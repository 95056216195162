import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "Bebas Neue",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "24PX",
          textTransform: "uppercase",
          backgroundColor: "#FFB539",
          padding: "0",
          lineHeight: "unset",
          height: "29px",
          borderRadius: "0",
          color: "#121212",
          boxShadow: "none",
          paddingTop: "2px",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#FFB539",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&.MuiDrawer-paper": {
            height: "74.25% !important",
          },
          boxShadow: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#121212",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "#ffb539",
        },
      },
    },
  },

  palette: {
    textColor: {
      main: "#fff",
      secondary: "#FFB539",
    },
    checbox: {
      background: "#FFB539",
      tickColor: "",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    h4: {
      fontSize: "24px",
    },
  },
});
theme.typography.h1 = {
  fontFamily: "Bebas Neue",
  fontSize: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "29px",
  },
};
theme.typography.h2 = {
  fontFamily: "Bebas Neue",
  fontSize: "48px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "29px",
  },
};
theme.typography.h3 = {
  fontFamily: "Bebas Neue",
  fontSize: "28px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
};
theme.typography.h4 = {
  fontFamily: "Bebas Neue",
  fontSize: "18px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
};
theme.typography.h5 = {
  fontFamily: "Lato",
  fontSize: "18px",
};
theme.typography.h6 = {
  fontFamily: "Lato",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "14px",
  },
};
export default theme;
