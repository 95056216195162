import React from "react";
import { LinearProgress, Box } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ mr: 1 }}>
        <LinearProgress
          sx={{
            background: "#fff",
            width: "189px",
            maxWidth: "100%",
            height: "27px",
          }}
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  );
}

export function ProgressBar({ difference }) {
  return (
    <Box>
      <LinearProgressWithLabel value={difference} />
    </Box>
  );
}
