import React from "react";
import { styled } from "@mui/material/styles";
import { Link as MUILink } from "@mui/material";

const CustomizedLink = styled(MUILink)(({ theme }) => ({
  textDecoration: "underline",
  fontSize: "18px",
  fontFamily: "Bebas Neue",
  color: "#fff",
  fontWeight: "400",
  textTransform: "uppercase",
  "&:hover": {
    color: "#FFB539",
  },
}));

export const Link = ({ styles, href, children, ...props }) => {
  return (
    <CustomizedLink href={href} {...props}>
      {children}
    </CustomizedLink>
  );
};
