import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.textColor.secondary,
  [theme.breakpoints.down("sm")]: {
    height: "26px",
    fontSize: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  height: "29px",
  paddingLeft: 15,
  paddingRight: 15,
  background: "none",
  border: "1px solid #FFB539",
  transition: "all 0.25s ease",
  transform: "scale(1)",
  "&:hover": {
    background: "none",
    border: "1px solid #FFB539",
    transform: "scale(1.1)"
  },
}));

export const SecondaryButton = ({ handleClick, children, ...props }) => {
  return (
    <StyledButton variant="outlined" onClick={handleClick} {...props}>
      {children}
    </StyledButton>
  );
};
