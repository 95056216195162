import React from "react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { initContract, NearContext } from "./near";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.nearInitPromise = initContract().then((nearContext) => {
  root.render(
    <StrictMode>
      <NearContext.Provider value={nearContext}>
        <Router>
          <ThemeProvider theme={theme}>
            <App />
            <CssBaseline />
          </ThemeProvider>
        </Router>
      </NearContext.Provider>
    </StrictMode>
  );
});
