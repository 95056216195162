import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)({
  fontStyle: "normal",
  fontWeight: "400",
  color: "#FFFFFF",
});

export const Heading = ({ variant, children, ...props }) => {
  return (
    <StyledTypography
      variant={variant}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};
