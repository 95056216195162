import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton } from "@mui/material";
import { Link, Drawer, NavLink } from "../shared";
import { SocialIcons } from "../components";
import { logo } from "../assets/images";

export const NavBarMobile = ({ isLoggedIn, pathname, links }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const splitLocation = pathname.split("/");
  const location = pathname.split("/details");

  return (
    <Grid item sx={{ display: "flex", flexGrow: "1" }}>
      <Drawer
        title="Antisocial Social School"
        open={openDrawer}
        closeDrawer={() => setOpenDrawer(false)}
      >
        {isLoggedIn && (
          <Grid
            container
            item
            direction="column"
            xs={12}
            backgroundColor="rgba(18, 18, 18, 0.99)"
          >
            {links.map((link) => (
              <Grid
                item
                sx={{ mb: "15px" }}
                backgroundColor={splitLocation[1] === "" ? "#1F1F1F" : ""}
                key={link.linkName}
              >
                <NavLink to={link.linkTo}>
                  <Link
                    sx={{
                      fontFamily: "Bebas Neue",
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "16x",
                      textTransform: "uppercase",
                      textDecoration: "none",
                      pl: 2.5,
                    }}
                    onClick={() => setOpenDrawer(false)}
                  >
                    {link.linkName}
                  </Link>
                </NavLink>
              </Grid>
            ))}
            <Grid item sx={{ mt: "18px", pl: 2.5 }}>
              <SocialIcons />
            </Grid>
          </Grid>
        )}
      </Drawer>

      <Grid item>
        <IconButton sx={{ color: "#FFF" }} onClick={() => setOpenDrawer(true)}>
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <NavLink to={"/"} style={{ display: "flex" }}>
          <img src={logo} alt="" height={"31px"} />
        </NavLink>
      </Grid>
    </Grid>
  );
};
