import React from "react";
import { Grid, Typography } from "@mui/material";
import { SocialIcons } from "../components";
import { LogoFooter } from "../assets/images";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Grid
      container
      item
      sx={{
        borderTop: "1px solid ",
        color: "#FFB539",
        justifyContent: "center",
        width: "100%",
        pt: 8,
        pb: 10,
        mt: "auto",
      }}
    >
      <Grid item>
        <img src={LogoFooter} alt="" />
      </Grid>

      <Grid container item justifyContent="center" sx={{ mt: 3 }}>
        <SocialIcons />
      </Grid>

      <Grid item>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ color: "white", mt: 6.75 }}
        >
          Copyright {currentYear} © Antisocial Ape Club. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};
