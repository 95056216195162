import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.textColor.black,
  height: "40px",
  [theme.breakpoints.down("sm")]: {
    height: "26px",
    fontSize: "16px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  [theme.breakpoints.down(360)]: {
    fontSize: "12px",
  },
  paddingLeft: 15,
  paddingRight: 15,
  transition: "all 0.25s ease",
  transform: "scale(1)",
  "&:hover": {
    transform: "scale(1.1)"
  }
}));

export const PrimaryButton = ({ handleClick, children, ...props }) => {
  return (
    <StyledButton variant="contained" onClick={handleClick} {...props}>
      {children}
    </StyledButton>
  );
};
