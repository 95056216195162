import React from "react";
import { Link } from "../shared";
import { Subtract, Discord, Twitter, Instagram } from "../assets/images";
import { Grid, CardMedia } from "@mui/material";
import { styled } from "@mui/material/styles";

const socialImages = [
  {
    image: Subtract,
    link: "https://www.tradeport.xyz/near/collection/asac.near?tab=marketview",
  },
  {
    image: Twitter,
    link: "https://twitter.com/ASAC_NFT",
  },
  {
    image: Discord,
    link: "https://discord.gg/Mz6YUjhF52",
  },
  {
    image: Instagram,
    link: "https://www.instagram.com/asac_nft/",
  },
];

const Img = styled(CardMedia)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "12px",
  },
  position: "relative",
  transition: "all 0.5s ease",
}));

export const SocialIcons = ({ styles = {} }) => {
  return (
    <Grid item>
      <Grid
        item
        sx={{
          display: "flex",
        }}
        direction="row"
        gap={1.25}
      >
        {socialImages.map(({ link, image }, i) => (
          <Link
            key={`image${i}`}
            href={link}
            target="_blank"
            className={'socialStyle'}
            sx={{
              width: { xs: 26, md: 33 },
              height: { xs: 26, md: 33 },
              ...styles,
            }}
          >
            <Img component="img" src={image} alt="image"></Img>
          </Link>
        ))}
      </Grid>
    </Grid>
  );
};
