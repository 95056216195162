import React from "react";
import { Grid, CardMedia, useMediaQuery, Link } from "@mui/material";
import { Title, Heading, PrimaryButton } from "../shared";
import { homeNftsList, nftsCollection } from "../constants";
import { Vector } from "../assets/images";
import { SocialIcons } from "../components";
import { useNavigate } from "react-router-dom";
import { useNearLogin, useSchool } from "../near";

export const Home = () => {
  const webSize = useMediaQuery("(min-width:1024px)");
  const mobileSize = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const account = useNearLogin();
  const { schoolEnabled } = useSchool();
  const iconCount = webSize ? 48 : 60;

  const openMarketplace = () =>
    window.open("https://www.tradeport.xyz/near/collection/asac.near?tab=marketview", "_blank").focus();

  return (
    <>
      <Grid container>
        {/*  antisocial ape club */}
        <Grid container sx={{ position: "relative" }}>
          <Grid container item sx={{ mt: 3.4 }}>
            {homeNftsList
              .filter((_, i) => i < iconCount)
              .map(({ img }) => (
                <Grid
                  item
                  xs
                  sx={{
                    display: "flex",
                    flexBasis: { xs: "12.5%", sm: "10%", md: "8.333333%" },
                  }}
                >
                  <div
                    sx={{
                      height: 0,
                      paddingTop: "100%",
                      overflow: "hidden",
                      boxSizing: "border-box",
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={img}
                      alt="asac"
                      sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            sx={{
              pl: { xs: "13px", md: 2.5 },
              py: { xs: 3, sm: 1 },
              pr: { xs: 2, md: 0 },
              zIndex: 11,
              bgcolor: "#121212",
              width: "100%",
              position: "absolute",
              bottom: "0",
            }}
          >
            <Grid item>
              <Grid item sx={{ mb: 2.5 }}>
                {mobileSize ? <SocialIcons /> : ""}
              </Grid>
              <Heading
                variant="h1"
                sx={{
                  fontSize: { sm: "40px", md: "48px" },
                  lineHeight: { xs: "29px", sm: "35px", md: "unset" },
                }}
              >
                welcome to the antisocial ape club
              </Heading>
              <Grid xs={10} lg={8}>
                <Heading
                  variant="h4"
                  sx={{
                    fontSize: { xs: "12px", sm: "18px" },
                    fontFamily: "Lato",
                    mt: { xs: "10px", md: "0" },
                  }}
                >
                  ASAC is a collection of 3333 unique, randomly generated pixel
                  art NFTs stored on the NEAR blockchain
                </Heading>
              </Grid>
              <PrimaryButton
                sx={{
                  px: 3,
                  mt: 2.25,
                  ".MuiButton-containedPrimary": { fontSize: "100px" },
                }}
                onClick={openMarketplace}
              >
                Buy an ASAC
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
        {/*  antisocial ape club */}
        {/* The collection */}
        <Grid
          container
          sx={{
            pl: { xs: 5.5, md: 10 },
            pr: { xs: 5.4, md: 0 },
            pb: 10,
            pt: 15,
            flexWrap: { xs: "wrap-reverse" },
          }}
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            sx={{
              pr: { xs: 0, md: 10 },
            }}
          >
            {nftsCollection.map(({ img }) => (
              <Grid
                item
                xs
                md={4}
                sx={{
                  display: "flex",
                  flexBasis: { xs: "33.333333%", md: "33.333333%" },
                }}
              >
                <div
                  sx={{
                    height: 0,
                    paddingTop: "100%",
                    overflow: "hidden",
                    boxSizing: "border-box",
                  }}
                >
                  <CardMedia
                    component="img"
                    src={img}
                    alt="asac"
                    sx={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              pt: { xs: 2, md: 0 },
              mb: { xs: 5, md: 0 },
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <Heading variant="h2">The collection</Heading>
            <Title />
            <Grid
              item
              sx={{
                my: 4.8,
                width: { xs: "100%", md: "55%" },
              }}
            >
              <Heading variant="h6">
                A collection of 3333 unique, randomly generated pixel art NFTs
                stored on the NEAR blockchain{" "}
              </Heading>
            </Grid>
            <Grid sx={{ display: "inline-block" }}>
              <PrimaryButton sx={{ px: 1.88 }} onClick={openMarketplace}>
                join the club
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
        {/* The collection */}
      </Grid>
      {schoolEnabled && (
        <Grid container item direction="column">
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderTop: "1px solid #FFFFFF",
              py: { xs: 5, sm: 10 },
              px: { xs: 5.4, md: 0 },
            }}
          >
            <Grid item>
              <Link
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1.25px solid #FFB539",
                  width: { xs: 50, sm: 68 },
                  height: { xs: 50, sm: 68 },
                }}
              >
                <img src={Vector} alt="" />
              </Link>
            </Grid>
            <Grid item sx={{ mt: 5, mb: 6 }}>
              <Heading variant="h2">Antisocial Social School</Heading>
              <Title />
            </Grid>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <Heading variant="h6">
                Upgrade $ASAC yield of your ASAC NFT by enrolling your Ape into
                the Antisocial Social School.
              </Heading>
              <Heading variant="h6">
                Learn more social skills, earn more $ASAC!
              </Heading>
            </Grid>
            <PrimaryButton
              sx={{ mt: 6 }}
              onClick={() => {
                account?.isLoggedIn
                  ? navigate("school")
                  : account.signIn("/school");
              }}
            >
              Enroll Now
            </PrimaryButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};
