import React from "react";
import { Routes as BrowserRoutes, Route, Navigate } from "react-router-dom";
import { School, Home, TokenDetails, TestnetUtils } from "./pages";
import { useNearLogin, useTestnetUtils } from "./near";

export const Routes = () => {
  const { isLoggedIn } = useNearLogin();
  const { isTestnet } = useTestnetUtils();

  return (
    <BrowserRoutes>
      <Route exact path="/" element={<Home />} />
      {isLoggedIn && (
        <>
          <Route path="school">
            <Route index element={<School />} />
            <Route path=":id" element={<TokenDetails />} />
          </Route>
          {isTestnet && <Route path="testnet" element={<TestnetUtils />} />}
        </>
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </BrowserRoutes>
  );
};
