import { Ape1, Ape2, Ape3, Ape4, Ape5, Ape6 } from "../assets/images";

export const homeNftsList = [...Array(60).keys()].map((i) => ({
  id: i.toString(),
  img: `https://storage.googleapis.com/ipfs-mirror/bafybeicj5zfhe3ytmfleeiindnqlj7ydkpoyitxm7idxdw2kucchojf7v4/${i + 100}.png`,
}));

export const nftsCollection = [
  { img: Ape1 },
  { img: Ape2 },
  { img: Ape3 },
  { img: Ape4 },
  { img: Ape5 },
  { img: Ape6 },
];
