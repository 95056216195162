import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { NavBarMobile, NavBarDesktop } from "../components";
import { PrimaryButton } from "../shared";
import { useNearLogin, useSchool, useTestnetUtils } from "../near";
import { useNavigate } from "react-router-dom";

export const NavBar = () => {
  const { getFtBalanceOfOwner, schoolEnabled } = useSchool();
  const account = useNearLogin();
  const navigate = useNavigate();
  const { isTestnet } = useTestnetUtils();

  const [userAsacBalance, setUserAsacBalance] = useState(null);

  const matches = useMediaQuery("(min-width: 900px)");

  const { pathname } = useLocation();

  const linkDetails = [
    {
      linkName: "Home",
      linkTo: "/",
    },
    ...(schoolEnabled
      ? [
          {
            linkName: "Antisocial Social School",
            linkTo: "/school",
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUserAsac = async () => {
      const asacBalance = await getFtBalanceOfOwner();
      if (!asacBalance.includes(".")) {
        setUserAsacBalance(asacBalance);
      }
      const [main, decimal] = asacBalance.split(".");
      setUserAsacBalance(`${main}.${decimal.slice(0, 2)}`);
    };
    getUserAsac();
  }, [getFtBalanceOfOwner]);

  return (
    <Grid
      container
      item
      sx={{ display: "flex", flexGrow: "1" }}
      alignItems="center"
    >
      {matches ? (
        <NavBarDesktop
          pathname={pathname}
          links={linkDetails}
          isLoggedIn={account?.isLoggedIn}
        />
      ) : (
        <NavBarMobile
          pathname={pathname}
          links={linkDetails}
          isLoggedIn={account?.isLoggedIn}
        />
      )}

      <Grid
        sx={{
          display: "flex",
        }}
      >
        {userAsacBalance && (
          <PrimaryButton
            onClick={() => isTestnet && navigate("/testnet")}
            sx={{ px: { xs: "5px", sm: 3, cursor: "initial" } }}
          >
            {userAsacBalance} $ASAC
          </PrimaryButton>
        )}
        {account.isLoggedIn ? (
          <PrimaryButton
            sx={{ ml: { xs: 1, sm: 2.5 }, px: { xs: "5px", sm: 3 } }}
            onClick={account.signOut}
          >
            {account.accountId}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            sx={{ ml: { xs: 1, sm: 2.5 }, px: { xs: "5px", sm: 3 } }}
            onClick={() => account.signIn()}
          >
            Connect Wallet
          </PrimaryButton>
        )}
      </Grid>
    </Grid>
  );
};
