import React from "react";
import { Grid } from "@mui/material";
import { Footer, NavBar } from "./components";
import { Routes } from "./Routes";
import "./App.css";

function App() {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
      }}
    >
      <Grid item sx={{ pt: 2.5, px: { xs: 2, sm: 6.25 }, width: "100%" }}>
        <NavBar />
        <Routes />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default App;
