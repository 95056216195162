import React, { useContext, createContext, useEffect } from "react";
import {
  connect,
  Contract,
  keyStores,
  WalletConnection,
  utils,
} from "near-api-js";
import { Buffer } from "buffer";
import getConfig from "./config";
global.Buffer = Buffer;

const nearConfig = getConfig(
  process.env.REACT_APP_ENVIRONMENT || "development"
);

export const NearContext = createContext(null);

const FULL_GAS = "300000000000000";

// Initialize contract & set global variables
export async function initContract() {
  // Initialize connection to the NEAR testnet
  const near = await connect(
    Object.assign(
      { deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } },
      nearConfig
    )
  );

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  const walletConnection = new WalletConnection(near, "asac");

  const schoolContract = await new Contract(
    walletConnection.account(),
    nearConfig.schoolContractName,
    {
      viewMethods: [
        "get_owner_id",
        "get_ft_contract_id",
        "get_nft_contract_id",
        "get_locking_days",
        "get_tuition_fee",
        "nft_traits",
        "nft_enrolled_for_owner",
        "nft_tuition_paid",
      ],
      changeMethods: ["withdraw", "graduate"],
    }
  );

  const nftContract = await new Contract(
    walletConnection.account(),
    nearConfig.nftContractName,
    {
      viewMethods: [
        "nft_token",
        "nft_supply_for_owner",
        "nft_tokens_for_owner",
        "nft_metadata",
      ],
      changeMethods: [
        "nft_transfer_call",
        ...(nearConfig.networkId === "testnet" ? ["nft_mint_one"] : []),
      ],
    }
  );

  const ftContract = await new Contract(
    walletConnection.account(),
    nearConfig.ftContractName,
    {
      viewMethods: ["ft_balance_of"],
      changeMethods: ["ft_transfer_call"],
    }
  );

  const faucetContract =
    nearConfig.networkId === "testnet"
      ? await new Contract(
          walletConnection.account(),
          nearConfig.ftFaucetContractName,
          {
            changeMethods: ["get_ft"],
          }
        )
      : null;

  return {
    near,
    config: nearConfig,
    walletConnection,
    schoolContract,
    nftContract,
    ftContract,
    faucetContract
  };
}

export function useNearLogin() {
  const context = useContext(NearContext);
  if (context === null) {
    throw new Error("useNear must be used within a NearProvider.");
  }
  const accountId = context.walletConnection.getAccountId();

  const signIn = (path="/") => {
    context.walletConnection.requestSignIn({
      contractId: nearConfig.nftContractName,
      successUrl: `${window.location.origin}${path}`
    });
  };

  const signOut = () => {
    context.walletConnection.signOut();
    window.location.replace(window.location.href);
  };

  // context.walletConnection.getAccountId()
  return {
    isLoggedIn: !!accountId,
    accountId,
    signIn,
    signOut,
  };
}

export function useSchool() {
  const context = useContext(NearContext);
  // console.log({ context });
  if (context === null) {
    throw new Error("useNear must be used within a NearProvider.");
  }

  const { schoolContract, ftContract, nftContract, walletConnection } = context;

  const account = walletConnection.account();
  const accountId = account.accountId;

  const assertLoggedIn = () => {
    if (!accountId) throw Error("Must be logged in");
  };

  const getLockingDays = async () => {
    return await schoolContract.get_locking_days();
  };

  const getTuitionFee = async () => {
    return await schoolContract.get_tuition_fee();
  };

  const getTutitionFeeFormatted = async () => {
    const tuition = await getTuitionFee();
    return utils.format.formatNearAmount(tuition);
  };

  const getNftTraits = async (tokenId) => {
    return await schoolContract.nft_traits({ token_id: tokenId });
  };

  const getNftsEnrolledForOwner = async () => {
    assertLoggedIn();
    const limit = 10;
    let enrolled_tokens = [];
    while (true) {
      const enrolled = await schoolContract.nft_enrolled_for_owner({
        account_id: accountId,
        from_index: 0,
        limit: limit,
      });
      enrolled.forEach((t) => enrolled_tokens.push(t));

      if (enrolled.length < limit) {
        return enrolled_tokens;
      }
    }
  };

  const getTokenTuitionStatus = async (tokenId) => {
    return await schoolContract.nft_tuition_paid({ token_id: tokenId });
  };

  const withdraw = async (tokenId) => {
    assertLoggedIn();
    return await schoolContract.withdraw({
      args: { token_id: tokenId },
      amount: "1",
      callbackUrl: `${window.location.origin}/school/${tokenId}`,
    });
  };

  const graduate = async (tokenId) => {
    assertLoggedIn();
    return await schoolContract.graduate({
      args: { token_id: tokenId },
      amount: "1",
      callbackUrl: `${window.location.origin}/school/${tokenId}`,
    });
  };

  const enroll = async (tokenId) => {
    assertLoggedIn();
    return await nftContract.nft_transfer_call({
      args: {
        receiver_id: schoolContract.contractId,
        token_id: tokenId,
        memo: "Enroll to ASAC School",
        msg: "enroll",
      },
      amount: "1",
      gas: FULL_GAS,
      callbackUrl: `${window.location.origin}/school/${tokenId}`,
    });
  };

  const getToken = async (tokenId) => {
    const metadata = await nftContract.nft_metadata();

    const token = await nftContract.nft_token({ token_id: tokenId });

    return {
      ...token,
      metadata: {
        ...token.metadata,
        media: `https://storage.googleapis.com/ipfs-mirror/bafybeicj5zfhe3ytmfleeiindnqlj7ydkpoyitxm7idxdw2kucchojf7v4/${token.metadata.media}`,
        reference: `https://storage.googleapis.com/ipfs-mirror/bafybeicj5zfhe3ytmfleeiindnqlj7ydkpoyitxm7idxdw2kucchojf7v4/${token.metadata.reference}`,
      },
    };
  };

  const getNftsForOwner = async () => {
    assertLoggedIn();
    const [metadata, supplyForOwner] = await Promise.all([
      nftContract.nft_metadata(),
      nftContract.nft_supply_for_owner({ account_id: accountId }),
    ]);

    const tokens = await Promise.all(
      [...Array(parseInt(supplyForOwner)).keys()].map((i) =>
        nftContract.nft_tokens_for_owner({
          account_id: accountId,
          from_index: i.toString(),
          limit: 1,
        })
      )
    );

    return tokens
      .map((_tokens) => _tokens[0])
      .map((t) => ({
        ...t,
        metadata: {
          ...t.metadata,
          media: `${metadata.base_uri}/${t.metadata.media}`,
          reference: `${metadata.base_uri}/${t.metadata.reference}`,
        },
      }));
  };

  const getFtBalanceOfOwner = async () => {
    assertLoggedIn();

    const balance = await ftContract.ft_balance_of({ account_id: accountId });
    // console.log(
    //   "utils.format.formatNearAmount(balance): ",
    //   utils.format.formatNearAmount(balance)
    // );
    return utils.format.formatNearAmount(balance);
  };

  const payTuition = async (tokenId) => {
    console.log({ tokenId });
    assertLoggedIn();

    const tuitionFee = await getTuitionFee();

    return await ftContract.ft_transfer_call({
      args: {
        receiver_id: schoolContract.contractId,
        amount: tuitionFee,
        memo: `Pay tuition for ${Number(tokenId)}`,
        msg: JSON.stringify({
          action: "pay_tuition",
          token_id: tokenId,
        }),
      },
      amount: "1",
      gas: FULL_GAS,
      callbackUrl: `${window.location.origin}/school/${tokenId}`,
    });
  };

  return {
    schoolEnabled: !!process.env.REACT_APP_SCHOOL_ENABLED,
    getLockingDays,
    getTuitionFee: getTutitionFeeFormatted,
    getNftTraits,
    getNftsEnrolledForOwner,
    getTokenTuitionStatus,
    getNft: getToken,
    getNftsForOwner,
    getFtBalanceOfOwner,
    payTuition,
    enroll,
    withdraw,
    graduate,
  };
}

export const useTestnetUtils = () => {
  const context = useContext(NearContext);
  if (context === null) {
    throw new Error("useNear must be used within a NearProvider.");
  }

  const { nftContract, faucetContract, config } = context;

  const mintNft = async () => {
    await nftContract.nft_mint_one({
      args: {},
      amount: utils.format.parseNearAmount("0.1"),
      gas: "100000000000000"
    })
  }

  const getFt = async () => {
    await faucetContract.get_ft({
      args: {},
      amount: "1",
      gas: "100000000000000"
    })
  }

  return {
    mintNft,
    getFt,
    isTestnet: config.networkId === "testnet"
  }
};
