import React from "react";
import { Grid } from "@mui/material";
import { SchoolTokenCard } from "./SchoolTokenCard";

export const NFTsListing = ({ tokens }) => {
  return (
    <Grid container item rowSpacing={3} columnSpacing={3}>
      {tokens.map((token) => (
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <SchoolTokenCard token={token} />
        </Grid>
      ))}
    </Grid>
  );
};
