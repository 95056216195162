import React, { useState, useEffect } from "react";
import { useSchool } from "../near";

export const TokenStatus = {
  idle: "idle",
  tuitionPaid: "tuitionPaid",
  enrolled: "enrolled",
  readyToGraduate: "readyToGraduate",
};

export const checkboxes = [
  {
    id: 0,
    text: "Idle",
    filter: (token) => !token.isEnrolled,
  },
  {
    id: 1,
    text: "Studying",
    filter: (token) => token?.tokenStatus === TokenStatus.enrolled,
  },
  {
    id: 2,
    text: "To Graduate",
    filter: (token) => token?.tokenStatus === TokenStatus.readyToGraduate,
  },
];

const useAllUserTokens = () => {
  const [allTokens, setAllTokens] = useState([]);
  const [loadingCount, setLoadingCount] = useState(null);
  const school = useSchool();
  const now = Date.now() * 1000000;

  const setTokenStatus = (token) => {
    const selectStatus = () => {
      if (!token.status) return TokenStatus.idle;
      if (!token.status.start_time) return TokenStatus.tuitionPaid;
      if (token.status.unlock_at >= now) return TokenStatus.enrolled;
      return TokenStatus.readyToGraduate;
    };

    const status = selectStatus();

    return {
      ...token,
      tokenStatus: status,
      isEnrolled:
        status === TokenStatus.enrolled ||
        status === TokenStatus.readyToGraduate,
      timeToGraduate: token?.status?.unlock_at
        ? Math.max(0, token?.status?.unlock_at - now)
        : null,
    };
  };

  const mergeTokens = (nfts) => (_tokens) =>
    [
      ..._tokens,
      ...nfts.filter(
        (nft) => !_tokens.some((token) => token.token_id === nft.token_id)
      ),
    ];

  useEffect(() => {
    const enrichToken = async (token) => {
      const traits = await school.getNftTraits(token.token_id);
      const status = await school.getTokenTuitionStatus(token.token_id);

      return {
        ...token,
        traits: traits?.traits || [],
        status,
      };
    };

    setLoadingCount((n) => (n || 0) + 1);

    school
      .getNftsForOwner()
      .then((tokens) => Promise.all(tokens.map(enrichToken)))
      .then((nfts) => {
        setAllTokens(mergeTokens(nfts));
        setLoadingCount((n) => n - 1);
      });
  }, []);

  useEffect(() => {
    const enrichEnrolledToken = async (token) => {
      const nft = await school.getNft(token.token_id);
      const traits = await school.getNftTraits(token.token_id);
      return {
        ...nft,
        status: token,
        traits: traits?.traits || [],
      };
    };

    setLoadingCount((n) => (n || 0) + 1);

    school
      .getNftsEnrolledForOwner()
      .then((tokens) => Promise.all(tokens.map(enrichEnrolledToken)))
      .then((nfts) => {
        setAllTokens(mergeTokens(nfts));
        setLoadingCount((n) => n - 1);
      });
  }, []);

  return {
    allTokens: allTokens
      .map(setTokenStatus)
      .sort((t1, t2) => Number(t1.token_id) - Number(t2.token_id)),
    loading: loadingCount !== 0,
  };
};

export const useTokenList = () => {
  const { allTokens } = useAllUserTokens();
  const [selectedFilter, setSelectedFilter] = useState(null);

  const setCheckboxTicked = (id) => {
    setSelectedFilter(id === selectedFilter ? null : id);
  };

  const applyFilter = (_tokens) => {
    if (selectedFilter === null) {
      return _tokens;
    }
    const filter = checkboxes.find((c) => c.id === selectedFilter).filter;
    return _tokens.filter(filter);
  };

  return {
    tokens: applyFilter(allTokens),
    checkboxes: checkboxes.map((c) => ({
      text: c.text,
      isChecked: c.id === selectedFilter,
      onClicked: () => setCheckboxTicked(c.id),
    })),
  };
};

export const useToken = () => {
  const { allTokens, loading } = useAllUserTokens();

  const getUserToken = (tokenId) => {
    return allTokens.find((token) => token.token_id === tokenId);
  };

  return {
    getUserToken,
    loading,
  };
};

export const useTokenAction = () => {
  const school = useSchool();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    school
      .getFtBalanceOfOwner()
      .then((balance) => setInfo((_info) => ({ ..._info, balance })));
    school
      .getTuitionFee()
      .then((fee) => setInfo((_info) => ({ ..._info, fee })));
  }, []);

  const getActions = (token) => {
    if (token.tokenStatus === TokenStatus.idle) {
      return {
        actionText: "pay tuition",
        action: () => school.payTuition(token.token_id),
        enabled:
          Number(info?.balance?.replace(",", "")) >
            Number(info?.fee?.replace(",", "")) && token.traits.length < 4,
      };
    }
    if (token.tokenStatus === TokenStatus.tuitionPaid) {
      return {
        actionText: "enroll",
        action: () => school.enroll(token.token_id),
        enabled: token.traits.length < 4,
      };
    }
    if (token.tokenStatus === TokenStatus.enrolled) {
      return {
        actionText: "withdraw",
        action: () => school.withdraw(token.token_id),
        enabled: true,
      };
    }
    if (token.tokenStatus === TokenStatus.readyToGraduate) {
      return {
        actionText: "graduate",
        action: () => school.graduate(token.token_id),
        enabled: true,
      };
    }
    return {};
  };

  return getActions;
};
