import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const LinkStlyed = styled(RouterNavLink)(({ theme, styles }) => ({
  textDecoration: styles.textDecoration,
}));

export const NavLink = ({ to, children, ...props }) => {
  return (
    <LinkStlyed to={to} styles={{ textDecoration: "none" }} {...props}>
      {children}
    </LinkStlyed>
  );
};
