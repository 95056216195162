import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { NFTsListing } from "../components/NFTsListing";
import { TickItem, Heading, Title } from "../shared";
import { useTokenList } from "../hooks/useTokens";

export const School = () => {
  const { tokens, checkboxes } = useTokenList();

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      rowSpacing={3}
      columnSpacing={3}
      sx={{
        mt: 8,
        mb: 10,
      }}
    >
      <Grid
        item
        sx={{ mb: 6, width: "100%", textAlign: { xs: "center", md: "left" } }}
      >
        <Heading variant="h2">ANTISOCIAL SOCIAL SCHOOL</Heading>
        <Title />
      </Grid>
      <Grid item container xs={12} direction="row" columnSpacing={3}>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent={{ xs: "center", sm: "flex-start" }}
          columnSpacing={2}
          rowSpacing={2}
          sx={{ mb: 3 }}
        >
          {checkboxes.map((item) => (
            <Grid item key={item.text}>
              <TickItem
                text={item.text}
                isChecked={item.isChecked}
                onChange={item.onClicked}
              />
            </Grid>
          ))}
        </Grid>
        <NFTsListing tokens={tokens} />
      </Grid>
    </Grid>
  );
};
