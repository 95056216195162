import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Drawer as MUIDrawer, Toolbar } from "@mui/material";
import { logo } from "../assets/images";
import { Link } from "react-router-dom";

export const Drawer = ({ title, open, closeDrawer, children, ...props }) => {
  return (
    <MUIDrawer
      anchor="left"
      sx={{ width: 250, color: "#121212", background: "none" }}
      open={open}
      onClose={() => closeDrawer(false)}
      {...props}
    >
      <Toolbar sx={{ width: 296, backgroundColor: "rgba(18, 18, 18, 0.99)" }}>
        <Grid container alignItems="center">
          <Grid item sx={{ mr: 1.2, display: "flex" }}>
            <CloseIcon
              sx={{ cursor: "pointer", color: "#FFFFFF" }}
              onClick={() => closeDrawer(false)}
            />
          </Grid>

          <Grid
            item
            sx={{
              flexGrow: "1",
            }}
          >
            <Link to={"/"} style={{ display: "flex" }}>
              <img src={logo} alt="" width={"58px"} />
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      {children}
    </MUIDrawer>
  );
};
