import React from "react";
import { Grid } from "@mui/material";
import { Skill } from "../shared";

const colors = {
  Speaking: { background: "#788BFF" },
  Listening: { background: "#EB5E28" },
  Flirting: { background: "#00CC66" },
  Writing: { background: "#5D2E8C" },
};

export const Skills = ({ traits }) => {
  return (
    <Grid conatiner>
      {traits &&
        traits.map((trait) => (
          <Skill background={colors[trait].background}>{trait}</Skill>
        ))}
    </Grid>
  );
};
