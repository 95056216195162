import React, { useEffect, useState } from "react";
import { useTestnetUtils } from "../near";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { PrimaryButton } from "../shared";

export const TestnetUtils = () => {
  const utils = useTestnetUtils();

  return (
    <Grid
      sx={{
        mt: 2.875,
        maxWidth: "100% !important",
      }}
    >
      <Grid item style={{ marginBottom: 10 }}>
        <PrimaryButton onClick={utils.mintNft}>Get NFT</PrimaryButton>
      </Grid>
      <Grid item>
        <PrimaryButton onClick={utils.getFt}>Get $ASAC</PrimaryButton>
      </Grid>
    </Grid>
  );
};
