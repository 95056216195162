import React from "react";
import { Card, Grid, Box } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { Heading, NavLink, SecondaryButton } from "../shared";

export const TokenCard = ({
  art,
  name,
  actionText,
  action,
  tokenId,
  ...props
}) => {
  return (
    <Grid container>
      <Card
        sx={{
          background: "#292929",
          p: { xs: 1, sm: 2.7 },
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <ImageListItem
          sx={{
            width: "100%",
          }}
        >
          <img src={art} alt="" />
        </ImageListItem>
        <Box sx={{ mt: 2.49, mb: 1.24 }}>
          <Heading variant="h3" color="#fff">
            {name}
          </Heading>
        </Box>
        <SecondaryButton handleClick={action} {...props}>
          {actionText}
        </SecondaryButton>
      </Card>
    </Grid>
  );
};
