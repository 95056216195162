import React from "react";
import { Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "108px",
  height: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "65px",
    height: "2px",
  },
  backgroundColor: "#ffb539",
}));

export const Title = () => {
  return (
    <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
      <StyledDivider></StyledDivider>
    </Grid>
  );
};
