import React from "react";
import { Grid } from "@mui/material";
import { NavLink, Link } from "../shared";
import { SocialIcons } from "../components";
import { logo } from "../assets/images";

export const NavBarDesktop = ({ isLoggedIn, links }) => {
  return (
    <Grid item sx={{ display: "flex", flexGrow: "1" }}>
      <Grid item sx={{ flexGrow: "1", display: "flex" }}>
        <NavLink to={"/"} sx={{ display: "flex" }}>
          <img src={logo} alt="" style={{width: 31, height: 31}} />
        </NavLink>
      </Grid>

      {isLoggedIn &&
        links.map((link) => (
          <Grid item sx={{ display: "flex", alignItems: "center", paddingLeft: 2 }} key={link.linkName} >
            <NavLink to={link.linkTo}>
              <Link
                sx={{
                  textDecoration: "underline",
                  fontFamily: "Bebas Neue",
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: "18px",
                  textTransform: "uppercase",
                }}
              >
                {link.linkName}
              </Link>
            </NavLink>
          </Grid>
        ))}
      <Grid
        item
        sx={{
          mx: { xs: 2.5, lg: 10 },
        }}
      >
        <SocialIcons />
      </Grid>
    </Grid>
  );
};
