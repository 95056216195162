import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Heading, Checkbox } from "../shared";

const Container = styled(Box)(({ theme }) => ({
  background: "#292929",
  [theme.breakpoints.down("sm")]: {
    paddingRight: 10,
  },
  paddingRight: 20,
  height: 42,
  display: "flex",
  alignItems: "center",
}));

export const TickItem = ({ text, onChange, isChecked }) => {
  return (
    <Container>
      <Checkbox
        text={text}
        onChange={onChange}
        checked={isChecked}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Heading variant="h4" sx={{ fontFamily: "Bebas Neue", lineHeight: 0 }}>
        {text}
      </Heading>
    </Container>
  );
};
