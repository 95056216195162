import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme, styles }) => ({
  color: "#fff",
  fontSize: "16px",
  width: "75px",
  maxWidth: "100%",
  marginRight: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    marginRight: "10px",
  },
  background: styles.background,
  "&:hover": {
    background: styles.background,
  },
}));

export const Skill = ({ background, children }) => {
  return <StyledButton styles={{ background }}>{children}</StyledButton>;
};
