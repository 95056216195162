import React from "react";
import { TokenCard } from "../shared";
import { Grid } from "@mui/material";
import { useTokenAction } from "../hooks/useTokens";
import { NavLink } from "../shared";

export const SchoolTokenCard = ({ token }) => {
  const getActions = useTokenAction();

  const action = getActions(token);

  return (
    <Grid item>
      <NavLink to={`/school/${token.token_id}`} sx={{ width: "100%" }}>
        <TokenCard
          tokenId={token.token_id}
          art={token.metadata.media}
          name={`ASAC #${token.metadata.title}`}
          actionText={action.actionText}
          onClick={(e) => {
            e.preventDefault();
            action.action();
          }}
          disabled={!action.enabled}
        />
      </NavLink>
    </Grid>
  );
};
