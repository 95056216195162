import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { NavLink, TokenCard, Heading } from "../shared";
import { ProgressBar, Skills } from "../components";
import { useSchool, useTestnetUtils } from "../near";
import { useParams } from "react-router-dom";
import { useToken, useTokenAction } from "../hooks/useTokens";
import { useNavigate } from "react-router-dom";

export const TokenDetails = () => {
  const [token, setToken] = useState({});
  const { getUserToken, loading: tokensLoading } = useToken();
  const navigate = useNavigate();
  const getTokenAction = useTokenAction();
  const { isTestnet } = useTestnetUtils();

  const { getLockingDays } = useSchool();
  const { id: tokenId } = useParams();
  const [lockingDays, setLockingDays] = useState(null);

  useEffect(() => {
    if (tokensLoading) return;
    const _token = getUserToken(tokenId);
    if (!_token) {
      navigate("/school");
    }
    console.log("token", _token);
    setToken(_token);
  }, [tokensLoading]);

  useEffect(() => {
    console.log("params", tokenId);
    getLockingDays().then(setLockingDays);
  }, []);

  const timeToGraduateDays =
    (token?.timeToGraduate / (24 * 60 * 60 * 1000_000_000)) *
    (isTestnet ? 1000 : 1);

  const tokenAction = getTokenAction(token);

  return (
    <Grid container item direction="row" xs={12} sx={{ mt: 10, pb: 5 }}>
      <Grid item xs={12} sx={{ mb: { xs: 7, sm: 2 } }}>
        <Grid container item alignItems="center">
          <NavLink to={"/school"}>
            <KeyboardBackspaceIcon fontSize="large" sx={{ color: "#ffffff" }} />
          </NavLink>
          <Heading
            variant="h2"
            sx={{ pl: { xs: "0", sm: 5 }, mx: { xs: "auto", sm: "0" }, pr: 4 }}
          >
            ASAC #{token.token_id || ""}
          </Heading>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={5} md={3}>
          <TokenCard
            art={token?.metadata?.media}
            name={`ASAC #${token?.metadata?.title || ""}`}
            actionText={tokenAction.actionText}
            action={tokenAction.action}
            disabled={!tokenAction.enabled}
          />
        </Grid>
        <Grid
          item
          sm={7}
          md
          sx={{
            pl: { xs: 0, sm: 2, md: "41px" },
            pt: { xs: 5, sm: 0 },
            width: "100%",
          }}
        >
          <Grid item>
            <Heading variant="h3" sx={{ fontSize: { xs: "28px" } }}>
              skills acquired
            </Heading>
          </Grid>
          {token?.traits?.length > 0 ? (
            <Grid item sx={{ mt: 2 }}>
              <Skills traits={token.traits} />
            </Grid>
          ) : (
            <Heading variant="h6">None</Heading>
          )}
          <Grid item sx={{ mt: "20px" }}>
            <Heading variant="h3" sx={{ fontSize: { xs: "28px" } }}>
              Time spent in school
            </Heading>
          </Grid>
          {token?.isEnrolled ? (
            <Grid container item sx={{ mt: "20px" }}>
              <Heading
                variant="h6"
                sx={{
                  fontFamily: "Bebas Neue",
                  color: "#FFB539",
                  lineHeight: { xs: "unset" },
                }}
              >
                {Math.min(Math.ceil(timeToGraduateDays), lockingDays)}/
                {lockingDays} Days
              </Heading>{" "}
              &nbsp;
              <Heading
                variant="h6"
                sx={{ fontFamily: "Bebas Neue", lineHeight: { xs: "unset" } }}
              >
                To Graduate
              </Heading>
            </Grid>
          ) : (
            <Heading variant="h6">None</Heading>
          )}
          {token?.isEnrolled && (
            <Grid item>
              <ProgressBar
                difference={
                  ((lockingDays - timeToGraduateDays) / lockingDays) * 100
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
